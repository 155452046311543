import { apiClient } from 'config/auth';
import { ENDPOINTS } from 'api/endpoints';
import { Options } from 'entries/filters';

export const getFilterBrandWeblinkExpiryDurations = async (): Promise<
  Options
> => {
  const response = await apiClient.get(
    ENDPOINTS.FILTER_BRAND_WEBLINK_EXPIRY_DURATIONS,
  );
  return response.data;
};
