export const INDEX = 'INDEX';
export const BRANDS_LANDING = 'BRANDS_LANDING';
export const SIGN_IN_LANDING = 'SIGN_IN_LANDING';
export const ORDERS_LANDING = 'ORDERS_LANDING';
export const CLIENTS_LANDING = 'CLIENTS_LANDING';
export const CATALOGS_LANDING = 'CATALOGS_LANDING';
export const PRODUCTS_LANDING = 'PRODUCTS_LANDING';
export const SUPPLIERS_LANDING = 'SUPPLIERS_LANDING';
export const BRAND_INFORMATION = 'BRAND_INFORMATION';
export const CLIENT_INFORMATION = 'CLIENT_INFORMATION';
export const CATALOG_INFORMATION = 'CATALOG_INFORMATION';
export const SUPPLIER_INFORMATION = 'SUPPLIER_INFORMATION';
export const PAGE_NOT_FOUND = 'PAGE_NOT_FOUND';
export const RESET_PASSWORD_LANDING = 'RESET_PASSWORD_LANDING';
export const ORDER_INFORMATION_LANDING = 'ORDER_INFORMATION_LANDING';
export const CREATE_NEW_PASSWORD_LANDING = 'CREATE_NEW_PASSWORD_LANDING';
export const RESET_PASSWORD_LINK_EXPIRED_LANDING =
  'RESET_PASSWORD_LINK_EXPIRED_LANDING';
export const BRAND_PRODUCTS = 'BRAND_PRODUCTS';
export const CLIENT_CATALOG = 'CLIENT_CATALOG';
export const CATALOG_CLIENT = 'CATALOG_CLIENT';
export const SHOPPING_CARTS_LANDING = 'SHOPPING_CARTS_LANDING';
export const SHOPPING_CARTS_INFORMATION = 'SHOPPING_CARTS_INFORMATION';
export const XO_XO_SUPPLIER_CHECK = 'XO_XO_SUPPLIER_CHECK';
export const INVOICES = 'INVOICES';
export const FUNDINGS = 'FUNDINGS';
export const INVENTORY_INFORMATION = 'INVENTORY_INFORMATION';
export const USERS_LANDING = 'USERS_LANDING';
export const USER_INFORMATION = 'USER_INFORMATION';
export const ANALYTICS = 'ANALYTICS';
export const FINANCE_OPERATION = 'FINANCE_OPERATION';
export const MAINTENANCE = 'MAINTENANCE';
export const ENEBA = 'ENEBA';
export const RECONCILIATION = 'RECONCILIATION';
export const RECONCILIATION_PROCESS = 'RECONCILIATION_PROCESS';
export const CLIENT_HOME = 'CLIENT_HOME';
export const MY_COMPANY = 'MY_COMPANY';
export const GAMIVO = 'GAMIVO';
export const INVENTORY_REPORTS = 'INVENTORY_REPORTS';
export const INVENTORY_REPORT_INFORMATION = 'INVENTORY_REPORT_INFORMATION';
export const PRODUCT_RULES = 'PRODUCT_RULES';
export const RESTORE_CATALOG = 'RESTORE_CATALOG';
export const CARDS_LANDING = 'CARDS_LANDING';
export const CLIENT_REPORT = 'CLIENT_REPORT';
export const SUPPLIER_REPORT = 'SUPPLIER_REPORT';
export const BRAND_REPORT = 'BRAND_REPORT';
export const ENEBA_AUTO_MAP = 'ENEBA_AUTO_MAP';
export const BINANCE_REQUESTS = 'BINANCE_REQUESTS';
export const CATEGORIES_LANDING = 'CATEGORIES_LANDING';
export const SETINGS = 'SETINGS';
export const G2A = 'G2A';
export const INVENTORY_INVOICES = 'INVENTORY_INVOICES';
export const INVENTORY_STATISTICS = 'INVENTORY_STATISTICS';
export const INVENTORY_CARDS = 'INVENTORY_CARDS';
export const KINGUIN = 'KINGUIN';
export const BAMBOO_BRANDS_LANDING = 'BAMBOO_BRANDS_LANDING';
export const BAMBOO_CATALOGS_LANDING = 'BAMBOO_CATALOGS_LANDING';
export const BAMBOO_CATALOG_INFORMATION = 'BAMBOO_CATALOG_INFORMATION';
export const SUPPLIER_ORDER = 'SUPPLIER_ORDER';
export const REPORT_GENERATION = 'REPORT_GENERATION';
export const USER_DETAILS = 'USER_DETAILS';
export const FAQ = 'FAQ';
export const BUFFER_HISTORY = 'BUFFER_HISTORY';
export const REACTIVATE_PRODUCT = 'REACTIVATE_PRODUCT';

export const PERMISSIONS = {
  INDEX,
  ORDERS_LANDING,
  BRANDS_LANDING,
  CATALOG_CLIENT,
  CLIENTS_LANDING,
  SIGN_IN_LANDING,
  CATALOGS_LANDING,
  PRODUCTS_LANDING,
  BRAND_INFORMATION,
  SUPPLIERS_LANDING,
  CLIENT_INFORMATION,
  CATALOG_INFORMATION,
  PAGE_NOT_FOUND,
  RESET_PASSWORD_LANDING,
  ORDER_INFORMATION_LANDING,
  CREATE_NEW_PASSWORD_LANDING,
  RESET_PASSWORD_LINK_EXPIRED_LANDING,
  BRAND_PRODUCTS,
  CLIENT_CATALOG,
  SUPPLIER_INFORMATION,
  SHOPPING_CARTS_LANDING,
  SHOPPING_CARTS_INFORMATION,
  XO_XO_SUPPLIER_CHECK,
  INVOICES,
  FUNDINGS,
  INVENTORY_INFORMATION,
  USERS_LANDING,
  USER_DETAILS,
  USER_INFORMATION,
  ANALYTICS,
  FINANCE_OPERATION,
  MAINTENANCE,
  ENEBA,
  RECONCILIATION,
  RECONCILIATION_PROCESS,
  CLIENT_HOME,
  MY_COMPANY,
  GAMIVO,
  INVENTORY_REPORTS,
  INVENTORY_REPORT_INFORMATION,
  PRODUCT_RULES,
  RESTORE_CATALOG,
  CARDS_LANDING,
  CLIENT_REPORT,
  SUPPLIER_REPORT,
  BRAND_REPORT,
  BINANCE_REQUESTS,
  CATEGORIES_LANDING,
  ENEBA_AUTO_MAP,
  SETINGS,
  G2A,
  INVENTORY_INVOICES,
  INVENTORY_STATISTICS,
  INVENTORY_CARDS,
  KINGUIN,
  BAMBOO_BRANDS_LANDING,
  BAMBOO_CATALOGS_LANDING,
  BAMBOO_CATALOG_INFORMATION,
  SUPPLIER_ORDER,
  REPORT_GENERATION,
  FAQ,
  BUFFER_HISTORY,
  REACTIVATE_PRODUCT
} as const;
