export enum CardsFilterField {
  DateFrom = 'dateFrom',
  DateTo = 'dateTo',
  Brand = 'brandId',
  Product = 'productId',
  Client = 'clientId',
  Supplier = 'supplierId',
  CardStatus = 'cardStatus',
  FilterBy = 'filterBy',
  FilterValue = 'filterValue',
  CardCode = 'cardCode',
  CardUrl = 'url',
  CodeFileReference = 'codeFileReference',
  G2AOrderNumber = 'g2AOrderNumber',
  EnebaOrderNumber = 'enebaOrderNumber',
  Denomination = 'denomination',
  ExpiringDate = 'expiringDate',
  SupplierOrderNumber = 'supplierOrderNumber',
  WebLink = 'webLink',
}

export interface FilterInputs {
  [CardsFilterField.DateFrom]: string;
  [CardsFilterField.DateTo]: string;
  [CardsFilterField.Brand]: string;
  [CardsFilterField.Product]: string;
  [CardsFilterField.FilterBy]: string;
  [CardsFilterField.FilterValue]: string;
  [CardsFilterField.Client]: string;
  [CardsFilterField.Supplier]: string;
  [CardsFilterField.CardStatus]: string;
  [CardsFilterField.CardCode]: string;
  [CardsFilterField.CardUrl]: string;
  [CardsFilterField.CodeFileReference]?: string;
  [CardsFilterField.G2AOrderNumber]?: string;
  [CardsFilterField.EnebaOrderNumber]?: string;
  [CardsFilterField.Denomination]?: string;
  [CardsFilterField.ExpiringDate]: string;
  [CardsFilterField.SupplierOrderNumber]?: string;
  [CardsFilterField.WebLink]?: string;
}
