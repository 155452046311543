import { RoutePermissions } from 'entries/users';
import { routes } from 'routes/routes';

/**
 * Converts the raw route permissions data returned by the API into a structured
 * format that is easier to work with in the frontend.
 *
 * The raw data is an array of objects, where each object represents a route and
 * contains an array of actions. This function transforms it into an object where:
 * - Each route is a key.
 * - Each action is represented as a key (combining the endpoint and HTTP method) with a boolean value.
 *
 * Example Transformation:
 * Input:
 * [
 *   {
 *     route: 'api/users',
 *     actions: [
 *       { httpMethod: 'GET', endpoint: 'api/users' },
 *       { httpMethod: 'PUT', endpoint: 'api/users/{id}' },
 *     ],
 *   }
 * ]
 *
 * Output:
 * {
 *   'api/users': {
 *     'api/users/GET': true,
 *     'api/users/{id}/PUT': true,
 *   }
 * }
 *
 * @param routePermissions - The raw route permissions array from the API.
 * @returns A transformed object where route permissions are easy to check and use.
 */
export function convertRoutePermissions(
  routePermissions: RoutePermissions[],
): Record<string, Record<string, boolean>> {
  const permissionMap: Record<string, Record<string, boolean>> = {};

  routePermissions.forEach(({ route, actions }) => {
    if (!permissionMap[route]) {
      permissionMap[route] = {};
    }

    actions.forEach(({ httpMethod, endpoint }) => {
      const key = `${endpoint}/${httpMethod}`;
      permissionMap[route][key] = true;
    });
  });

  return permissionMap;
}

export function getNavPermissions(
  routePermissions: Record<string, Record<string, boolean>>,
): Record<string, boolean> {
  const permissionMap: Record<string, boolean> = {
    newOrderV2:
      routePermissions['api/baskets'] &&
      routePermissions['api/baskets']['api/baskets/checkout/POST'],
    [routes.shoppingCartsLandingV2]:
      !!routePermissions['api/baskets'] &&
      !!routePermissions['api/baskets']['api/baskets/GET'],
    [routes.bambooBrandsLanding]:
      !!routePermissions['api/brands'] &&
      !!routePermissions['api/brands']['api/brands/GET'],
    [routes.bambooCatalogsLanding]:
      !!routePermissions['api/catalogs'] &&
      !!routePermissions['api/catalogs']['api/catalogs/GET'],
    newOrder:
      routePermissions['api/baskets'] &&
      routePermissions['api/baskets']['api/baskets/checkout/POST'],
    [routes.shoppingCartsLanding]:
      !!routePermissions['api/baskets'] &&
      !!routePermissions['api/baskets']['api/baskets/GET'],
    [routes.ordersLanding]:
      !!routePermissions['api/orders'] &&
      (!!routePermissions['api/orders']['api/orders/GET'] ||
        !!routePermissions['api/orders']['api/orders/client/GET']), // For client orders
    [routes.cardsLanding]:
      !!routePermissions['api/cards'] &&
      !!routePermissions['api/cards']['api/cards/getcards/GET'],
    [routes.bufferHistory]:
      !!routePermissions['api/bufferedcards'] &&
      !!routePermissions['api/bufferedcards']['api/bufferedcards/history/GET'],
    [routes.index]:
      !!routePermissions['api/brands'] &&
      !!routePermissions['api/brands']['api/brands/GET'],
    [routes.brandsLanding]:
      !!routePermissions['api/brands'] &&
      !!routePermissions['api/brands']['api/brands/GET'],
    [routes.categoriesLanding]:
      !!routePermissions['api/category'] &&
      !!routePermissions['api/category']['api/category/GET'],
    [routes.productsLanding]:
      !!routePermissions['api/products'] &&
      !!routePermissions['api/products']['api/products/GET'],
    [routes.productRulesLanding]:
      !!routePermissions['api/supplierchangerules'] &&
      !!routePermissions['api/supplierchangerules'][
        'api/supplierchangerules/GET'
      ],
    [routes.catalogsLanding]:
      !!routePermissions['api/catalogs'] &&
      !!routePermissions['api/catalogs']['api/catalogs/GET'],
    [routes.inventoryProducts]:
      !!routePermissions['api/products'] &&
      !!routePermissions['api/products']['api/products/GET'],
    [routes.inventoryInvoices]:
      !!routePermissions['api/supplierinvoices'] &&
      !!routePermissions['api/supplierinvoices']['api/supplierinvoices/GET'],
    [routes.inventoryStatistics]:
      !!routePermissions['api/supplierinvoices'] &&
      !!routePermissions['api/supplierinvoices'][
        'api/supplierinvoices/supplier-invoice-statistic/GET'
      ],
    [routes.inventoryReports]:
      !!routePermissions['api/inventoryreport'] &&
      !!routePermissions['api/inventoryreport'][
        'api/inventoryreport/fetch-report/GET'
      ],
    [routes.inventoryCards]:
      !!routePermissions['api/inventory'] &&
      !!routePermissions['api/inventory']['api/inventory/cards/GET'],
    [routes.suppliersLanding]:
      !!routePermissions['api/suppliers'] &&
      !!routePermissions['api/suppliers']['api/suppliers/GET'],
    [routes.invoices]:
      !!routePermissions['api/invoices'] &&
      !!routePermissions['api/invoices']['api/invoices/GET'],
    [routes.funding]:
      !!routePermissions['api/funding-requests'] &&
      (!!routePermissions['api/funding-requests']['api/funding-requests/GET'] ||
        !!routePermissions['api/funding-requests'][
          'api/funding-requests/client/GET'
        ]),
    [routes.financeOperation]:
      !!routePermissions['api/transactions'] &&
      !!routePermissions['api/transactions']['api/transactions/GET'],
    [routes.reconciliationProcess]:
      !!routePermissions['api/reconciliation'] &&
      !!routePermissions['api/reconciliation'][
        'api/reconciliation/get-reconciled-records/GET'
      ],
    [routes.clientsLanding]:
      !!routePermissions['api/clients'] &&
      !!routePermissions['api/clients']['api/clients/GET'],
    [routes.eneba]:
      !!routePermissions['api/eneba'] &&
      !!routePermissions['api/eneba']['api/eneba/{accountid}/products/GET'],
    [routes.gamivo]:
      !!routePermissions['api/gamivo'] &&
      !!routePermissions['api/gamivo']['api/gamivo/products/GET'],
    [routes.g2a]:
      !!routePermissions['api/g2a'] &&
      !!routePermissions['api/g2a']['api/g2a/products/GET'],
    [routes.kinguin]:
      !!routePermissions['api/kinguin'] &&
      !!routePermissions['api/kinguin']['api/kinguin/products/GET'],
    [routes.binanceRequests]:
      !!routePermissions['api/binance'] &&
      !!routePermissions['api/binance']['api/binance/orders/GET'],
    [routes.reportGeneration]:
      !!routePermissions['api/reports'] &&
      !!routePermissions['api/reports']['api/reports/GET'],
    [routes.clientReport]: !!routePermissions['api/clientreport'],
    [routes.supplierReport]: !!routePermissions['api/supplierreport'],
    [routes.brandReport]: !!routePermissions['api/brandreport'],
    [routes.usersLanding]:
      !!routePermissions['api/users'] &&
      !!routePermissions['api/users']['api/users/GET'],
    [routes.settings]:
      !!routePermissions['api/settings'] &&
      !!routePermissions['api/settings'][
        'api/settings/get-local-parameters/GET'
      ],
    [routes.reconciliation]:
      !!routePermissions['api/reconciliation'] &&
      !!routePermissions['api/reconciliation'][
        'api/reconciliation/reconciliation-history/GET'
      ],
    [routes.catalogsClient]:
      !!routePermissions['api/catalogs'] &&
      !!routePermissions['api/catalogs']['api/catalogs/client/products/GET'],
    [routes.brandInformation]:
      !!routePermissions['api/brands'] &&
      !!routePermissions['api/brands']['api/brands/{id}/GET'],
    [routes.brandInformationV2]:
      !!routePermissions['api/brands'] &&
      !!routePermissions['api/brands']['api/brands/{id}/GET'],
    [routes.clientInformation]:
      !!routePermissions['api/clients'] &&
      !!routePermissions['api/clients']['api/clients/{id}/GET'],
    [routes.catalogInformation]:
      !!routePermissions['api/catalogs'] &&
      !!routePermissions['api/catalogs']['api/catalogs/{id}/GET'],
    [routes.supplierInformation]:
      !!routePermissions['api/suppliers'] &&
      !!routePermissions['api/suppliers']['api/suppliers/{id}/GET'],
    [routes.orderInformationLanding]:
      !!routePermissions['api/orders'] &&
      (!!routePermissions['api/orders']['api/orders/{id}/GET'] ||
        !!routePermissions['api/orders']['api/orders/client/GET']), // For client orders
    [routes.brandProducts]:
      !!routePermissions['api/brands'] && !!routePermissions['api/products'],
    [routes.clientCatalog]:
      !!routePermissions['api/catalogs'] &&
      !!routePermissions['api/catalogs']['api/catalogs/{id}/products/GET'],
    [routes.shoppingCartsInformation]:
      !!routePermissions['api/baskets'] &&
      !!routePermissions['api/baskets']['api/baskets/{id}/GET'],
    [routes.shoppingCartsInformationV2]:
      !!routePermissions['api/baskets'] &&
      !!routePermissions['api/baskets']['api/baskets/{id}/GET'],
    [routes.inventory]:
      !!routePermissions['api/inventory'] &&
      !!routePermissions['api/inventory']['api/inventory/{id}/GET'],
    [routes.userDetail]:
      !!routePermissions['api/users'] &&
      !!routePermissions['api/users']['api/users/{id}/GET'],
    [routes.usersInformation]:
      !!routePermissions['api/users'] &&
      !!routePermissions['api/users']['api/users/details/GET'],
    [routes.inventoryReportInformation]:
      !!routePermissions['api/inventoryreport'] &&
      !!routePermissions['api/inventoryreport'][
        'api/inventoryreport/fetch-report-information/GET'
      ],
    [routes.restoreCatalog]:
      !!routePermissions['api/catalogs'] &&
      !!routePermissions['api/catalogs'][
        'api/catalogs/restorecatalogitems/GET'
      ],
    [routes.reactivateProduct]: !!routePermissions['api/products'],
    [routes.resetCatalogItemsProduct]:
      !!routePermissions['api/catalogs'] &&
      !!routePermissions['api/catalogs'][
        'api/catalogs/restorecatalogitems/GET'
      ],
    [routes.enebaAutoMap]: !!routePermissions['api/eneba'],
    [routes.bambooCatalogsInformation]:
      !!routePermissions['api/catalogs'] &&
      !!routePermissions['api/catalogs']['api/catalogs/{id}/GET'],
    [routes.supplierOrder]:
      !!routePermissions['api/cards'] &&
      !!routePermissions['api/cards'][
        'api/cards/supplier-cards/{supplierid}/GET'
      ],
    // Routes that are not in the API and are not related to the user's role
    [routes.faq ?? '/']: true,
    [routes.resetPasswordLanding]: true,
    [routes.createNewPasswordLanding]: true,
    [routes.pageNotFound]: true,
    [routes.maintenance]: true,
  };

  return permissionMap;
}

export function getActionPermissions(
  routePermissions: Record<string, Record<string, boolean>>,
): Record<string, boolean> {
  const permissionMap: Record<string, boolean> = {
    // Order related actions
    createOrderV2:
      !!routePermissions['api/baskets'] &&
      routePermissions['api/baskets']['api/baskets/POST'],
    createOrder:
      !!routePermissions['api/baskets'] &&
      routePermissions['api/baskets']['api/baskets/POST'],
    createBrand:
      !!routePermissions['api/brands'] &&
      routePermissions['api/brands']['api/brands/POST'],

    // Buffered cards related actions
    exportBufferedCards:
      !!routePermissions['api/bufferedcards'] &&
      routePermissions['api/bufferedcards'][
        'api/bufferedcards/get-and-remove-cards-information-report/GET'
      ],
    currentBufferedCards:
      !!routePermissions['api/bufferedcards'] &&
      routePermissions['api/bufferedcards'][
        'api/bufferedCards/generate-current-buffer-report/GET'
      ],

    // Catalog related actions
    addAllBrandsToCatalog:
      !!routePermissions['api/catalogs-brands'] &&
      routePermissions['api/catalogs-brands'][
        'api/catalogs-brands/{catalogid}/add-all-brands/PUT'
      ],
    duplicateCatalog:
      !!routePermissions['api/catalogs'] &&
      routePermissions['api/catalogs'][
        'api/catalogs/{catalogid}/duplicate/POST'
      ],
    editCatalog:
      !!routePermissions['api/catalogs'] &&
      routePermissions['api/catalogs']['api/catalogs/{id}/PUT'],
    deleteCatalog:
      !!routePermissions['api/catalogs'] &&
      routePermissions['api/catalogs']['api/catalogs/{id}/DELETE'],
    editCatalogBrands:
      !!routePermissions['api/catalogs-brands'] &&
      routePermissions['api/catalogs-brands'][
        'api/catalogs-brands/{catalogid}/brands/add/POST'
      ],
    addCatalogBrand:
      !!routePermissions['api/catalogs-brands'] &&
      routePermissions['api/catalogs-brands'][
        'api/catalogs-brands/{catalogid}/brands/add/POST'
      ],
    editCatalogsClient:
      !!routePermissions['api/catalogs'] &&
      !!routePermissions['api/catalogs'][
        'api/catalogs/{id}/clients/delete/DELETE'
      ],
    addCatalogsClient:
      !!routePermissions['api/catalogs'] &&
      routePermissions['api/catalogs']['api/catalogs/{id}/clients/add/POST'],
    downloadPriceList:
      !!routePermissions['api/catalogs'] &&
      routePermissions['api/catalogs'][
        'api/catalogs/admincatalogitemsexcel/GET'
      ],
    downloadClientPriceList:
      !!routePermissions['api/catalogs'] &&
      routePermissions['api/catalogs'][
        'api/catalogs/clientcatalogitemsexcel/GET'
      ],
    addProductToCatalog:
      !!routePermissions['api/catalogs'] &&
      routePermissions['api/catalogs']['api/catalogs/{id}/products/add/POST'],
    editCatalogProduct:
      !!routePermissions['api/catalogs'] &&
      routePermissions['api/catalogs']['api/catalogs/{id}/products/edit/PUT'],

    // Brand related actions
    addSupplierToBrand:
      !!routePermissions['api/brands'] &&
      routePermissions['api/brands']['api/brands/{id}/add-supplier/POST'],
    changeBrandUrl:
      !!routePermissions['api/brands'] &&
      routePermissions['api/brands']['api/brands/{id}/urlbrand/PUT'],
    changeBrandCodeExtraction:
      !!routePermissions['api/brands'] &&
      routePermissions['api/brands']['api/brands/{id}/brandcodeextraction/PUT'],
    addBrand:
      !!routePermissions['api/brands'] &&
      routePermissions['api/brands']['api/brands/POST'],
    editBrand:
      !!routePermissions['api/brands'] &&
      routePermissions['api/brands']['api/brands/{id}/PUT'],
    changeBrandStatus:
      !!routePermissions['api/brands'] &&
      routePermissions['api/brands']['api/brands/change-status/PUT'],
    deleteBrand:
      !!routePermissions['api/brands'] &&
      routePermissions['api/brands']['api/brands/{id}/DELETE'],

    // Binance related actions
    refreshBinanceRequest:
      !!routePermissions['api/binance'] &&
      routePermissions['api/binance']['api/binance/refresh-order/POST'],
    refundBinanceRequest:
      !!routePermissions['api/binance'] &&
      routePermissions['api/binance']['api/binance/refund-order/POST'],
    resendBinanceRequest:
      !!routePermissions['api/binance'] &&
      routePermissions['api/binance']['api/binance/resend-order-codes/POST'],

    // Report generation related actions
    generateReport:
      !!routePermissions['api/reports'] &&
      routePermissions['api/reports']['api/reports/generate-report/POST'],

    // Filter related actions
    getSupplierFilter:
      !!routePermissions['api/filters'] &&
      routePermissions['api/filters']['api/filters/suppliers/GET'],
    getCatalogClientsFilter:
      !!routePermissions['api/filters'] &&
      routePermissions['api/filters']['api/filters/catalog-clients/GET'],

    getBrandWeblinkExpiryDurations:
      !!routePermissions['api/filters'] &&
      routePermissions['api/filters'][
        'api/filters/brand-weblink-expiry-durations/GET'
      ],

    // Category related actions
    editCategory:
      !!routePermissions['api/category'] &&
      routePermissions['api/category']['api/category/{id}/PUT'],
    deleteCategory:
      !!routePermissions['api/category'] &&
      routePermissions['api/category']['api/category/{id}/DELETE'],
    addCategory:
      !!routePermissions['api/category'] &&
      routePermissions['api/category']['api/category/POST'],
    deleteCategoryBrand:
      !!routePermissions['api/categories'] &&
      routePermissions['api/categories']['api/category/brands/{id}/DELETE'],
    addCategoryBrand:
      !!routePermissions['api/categories'] &&
      routePermissions['api/categories']['api/category/map-brands/POST'],

    // Client related actions
    addClient:
      !!routePermissions['api/clients'] &&
      routePermissions['api/clients']['api/clients/POST'],
    editClient:
      !!routePermissions['api/clients'] &&
      routePermissions['api/clients']['api/clients/{id}/PUT'],
    deleteClient:
      !!routePermissions['api/clients'] &&
      routePermissions['api/clients']['api/clients/{id}/DELETE'],
    editClientStatus:
      !!routePermissions['api/clients'] &&
      routePermissions['api/clients']['api/clients/change-status/PUT'],
  };

  return permissionMap;
}
